<template>
  <v-container fluid tag="section">
    <v-row justify="center" align-content="center">
      <template>
        <div>
           <v-avatar
              color="brown"
              size="60"
            >
              <v-img
                height="100%"
                :src="logo"
              >
              </v-img>
            </v-avatar>
        </div>
      </template>
    </v-row>
    <v-row>
      <v-container class="pa-1" style="max-width: 400px">
          <v-row>
            <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              sm="6"
            >
              <v-card
                class="text-center"
                height="150"
                :to="item.ruta">
                <v-icon size="100px">
                  {{ item.icon }}
                </v-icon>
                <h4 v-text="item.title"></h4>
              </v-card>
            </v-col>
          </v-row>
      </v-container>
    </v-row>
    <v-row justify="center" align-content="center">
      <codigoQr></codigoQr>
    </v-row>
  </v-container>
</template>
<script>
import codigoQr from '../../components/TemplateQr.vue';
export default {
  components: { codigoQr},
  data() {
     return {
      items: [
        {
          icon: 'mdi-clipboard-text-outline',
          ruta:'/tc-admin/cartas',
          title:'Menu'
        },
        {
          icon: 'mdi-pasta',
          ruta:'/tc-admin/platos',
          title:'Platos'
        },
        {
          icon: 'mdi-image-album',
          ruta:'/tc-admin/promociones',
          title:'Promoción'
        },
        {
          icon: 'mdi-cog-transfer-outline',
          ruta:'/tc-admin/configuraciones',
          title:'Perfil'
        },
      ],
      selected: [],
    }
  },
  methods: {
    test(){
      //const ruta = this.$route.path;
      //console.log(ruta);
    }
  },
  computed: {
   logo(){
     return this.$store.getters['auth/user'] ? `${process.env.VUE_APP_IMG_URL_API}images/restaurants/${this.$store.getters['auth/user'].logo}` : '';
    },
  }
}
</script>
