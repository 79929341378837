<template>

    <v-col cols="12" sm="7" md="5" lg="5" class="text-center">
      <span class="label__form primary--text text--lighten-5 my-3" v-text="'Código QR'"></span>
      <qrcode-vue :value="'https://todocartas.online/r/'+url" :size="200" renderAs="svg"></qrcode-vue>
      <v-btn small :loading="load_pdf" tile color="info" class="my-3" @click="print"><v-icon left>mdi-adobe-acrobat</v-icon>Descargar PDF</v-btn>
    </v-col>

</template>
<script>
import QrcodeVue from 'qrcode.vue'
export default {
  components: { QrcodeVue},
  name:'ComponentCodigoQR',
  data() {
    return {
      load_pdf: false,
      url:'',
      id:''
    }
  },
  created(){
    this.initDatos();
  },
  methods: {
    async print(){
	      this.load_pdf = true;
	      try{
	      	const { data } = await this.$api.get(`api/restaurants/downloadPdf?restaurant_id=${this.id}`);
	      	var link = document.createElement('a');
					link.download = `${this.url}.pdf`;
					link.href = 'data:application/octet-stream;base64,' + data.pdfBase64;
					link.setAttribute('class', 'd-none');
					document.body.appendChild(link);
					link.click();
          link.remove();
	      }
	      catch(e){
	      	this.snackbar = {
            text:  'Lo sentimos, hubo un error al Generar el PDF.',
            type: 'error',
            active: true
          }
	      }
	      finally{
	      	this.load_pdf = false;
	      }
    },
    initDatos(){
      this.url = this.$store.getters['auth/user'].url;
      this.id = this.$store.getters['auth/user'].restaurant_id;
    }
  }
}
</script>
